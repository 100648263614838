<template>
    <div class="home">

        <div style="
            height: 60px;
            line-height: 60px;
            right: 42px;
    position: fixed;
    text-align: right;
    font-size: 24px;"><i @click="chacha" class="el-icon-close"></i></div>
      <div class="pt20"></div>
    <div class="form">
      <el-form ref="form" label-width="180px">
          <el-form-item style="
    font-weight: bold;
    font-size: 16px;
    margin-top: 50px;" label="内容标签">
            <div class="flex" v-for="(item, index) in form"
          :key="index">
            <el-input class="inp" disabled v-model="item.name"></el-input>
            <el-button
            style="margin-left: 20px;"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteData(index)"
            ></el-button>
          </div>
          </el-form-item>
            <el-button
            style="margin-left: 320px;"
              type="primary"
              icon="el-icon-plus"
              circle
              @click="add"
            ></el-button>
      <el-form-item style="
    margin-top:20px;
    margin-left: 125px;">
        <el-button type="primary"  @click="onSubmit">完成</el-button>
      </el-form-item>
    </el-form>
</div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      initdate:{
        name:'内容列表',
        url:'/article/list',
        name2:'内容编辑',
        url2:'/article/artedit',
      },
      form: [],
        options:[],
    }
  },
  methods: {
      handleSelect(){
        if(sessionStorage.getItem('art') == 1){
        this.$router.push('/userexamine/articleviewedit');
        }else{
          this.$router.push('/article/artedit');
        }
      },
     onSubmit() {
      let postdata = this.form;
      let arr = [];
      let length = postdata.length;
      for(let i in postdata){
          arr.push(postdata[i].id);
      }
      arr = Array.from(new Set(arr));
      if(arr.length != length){
        this.$alert("标签重复");
        return;
      }
      sessionStorage.setItem("artedit", JSON.stringify(postdata));
      
      if(sessionStorage.getItem('art') == 1){
        this.$router.push('/userexamine/articleviewedit');
      }else if(sessionStorage.getItem('art') == 2){
        this.$router.push('/article/publishadd');
      }else{
        this.$router.push('/article/artedit');
      }
      
    },
     chacha() {
     
      sessionStorage.setItem("artedit", sessionStorage.getItem('chaba'));
      
      if(sessionStorage.getItem('art') == 1){
        this.$router.push('/userexamine/articleviewedit');
      }else if(sessionStorage.getItem('art') == 2){
        this.$router.push('/article/publishadd');
      }else{
        this.$router.push('/article/artedit');
      }
      
    },
    init(a){
        let _this = this;
        let obj = a==''?[]:JSON.parse(a);
        _this.form = obj;
        if(sessionStorage.getItem('art') == 1){
          _this.initdate = {
            url:'/userexamine/articlereview',
            url2:'/userexamine/articleviewedit',
            name:'内容审核',
            name2:'内容详情',
          }
        }
    },// 增加
    add() {
        this.$router.push('/article/selectarticlelabel');
    },
    // 删除
    deleteData(index) {
        this.form.splice(index, 1);
        sessionStorage.setItem("artedit", JSON.stringify(this.form));
    },
  },
  created(){
     let a = sessionStorage.getItem('artedit');
     if(sessionStorage.getItem('gpc') == 1){//判断从哪来
        sessionStorage.setItem("chaba", a);//叉叉关闭的时候需要
        sessionStorage.setItem("gpc", 0);
     }
     this.init(a);
  }
}
</script>
<style scoped>
.form{
    width: 520px;
    margin-left: 200px;
}
.flex{
    display: flex;margin-bottom: 10px;
}
.inp /deep/ .el-input__inner{
  color: #000;
}
</style>
